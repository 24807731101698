import * as React from 'react';
import {Stream} from '@cloudflare/stream-react';
import PropTypes from 'prop-types';
import {useRecordContext} from 'react-admin';

const CloudflareVideoField = (props) => {
  const {source} = props;
  const record = useRecordContext(props);
  return (
    <div style={{marginTop: 32, marginBottom: 24}}>
      {record.isReadyToStream && (
        <Stream controls src={record[source]} responsive={false} width="400" height="600" />
      )}
      {!record.isReadyToStream && (
        <p style={{backgroundColor: '#fee', padding: 12}}>Not ready to stream.</p>
      )}
    </div>
  );
};

CloudflareVideoField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default CloudflareVideoField;
