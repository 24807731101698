import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import {FileField, FileInput, useLogout, useNotify} from 'react-admin';
import {useForm} from 'react-final-form';
import * as tus from 'tus-js-client';

import config from '../config';
import {readAuthToken} from '../utils';

const TusVideoInput = (props) => {
  const form = useForm();
  const logout = useLogout();
  const notify = useNotify();

  const [displayStatus, setDisplayStatus] = useState('No file selected');

  const onProgress = useCallback(
    (bytesUploaded, bytesTotal) => {
      const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
      console.log(bytesUploaded, bytesTotal, percentage + '%');
      if (percentage === '100.00') {
        setDisplayStatus('Processing, please wait');
      } else {
        setDisplayStatus(`Uploaded ${percentage}%`);
      }
    },
    [setDisplayStatus]
  );

  const handleFileChange = useCallback(
    async (file) => {
      if (file === null) {
        // file was deleted
        setDisplayStatus('No file selected');
        return;
      }
      setDisplayStatus('Uploading');
      const token = readAuthToken(logout);
      // Create a new tus upload
      const upload = new tus.Upload(file, {
        endpoint: `${config.API_HOST}/admin/tus-upload?token=${token}`,
        retryDelays: [0, 3000, 5000, 10000, 20000],
        metadata: {
          filename: file.name,
          filetype: file.type,
        },
        onError: function (error) {
          const statusCode = error.originalResponse ? error.originalResponse.getStatus() : 0;
          console.log(statusCode);
          if (statusCode === 401) {
            logout();
            return;
          }
          notify(error);
        },
        onAfterResponse: (req, res) => {
          let streamMediaId = null;
          try {
            streamMediaId = res.getHeader('Stream-Media-ID');
            console.log(streamMediaId);
            form.change(props.source, streamMediaId);
          } catch (e) {
            //
          }
        },
        onProgress,
        onSuccess: function () {
          console.log('%s uploaded', upload.file.name);
          setDisplayStatus('Uploaded');
        },
      });

      // Check if there are any previous uploads to continue.
      upload.findPreviousUploads().then(function (previousUploads) {
        // Found previous uploads so we select the first one.
        if (previousUploads.length) {
          upload.resumeFromPreviousUpload(previousUploads[0]);
        }

        // Start the upload
        upload.start();
      });
    },
    [form, logout, notify, props.source, setDisplayStatus, onProgress]
  );

  return (
    <div style={{padding: 12, background: '#f0f0f0', marginBottom: 12, display: 'inline-block'}}>
      <FileInput onChange={handleFileChange} {...props}>
        <FileField source="rawFile.name" />
      </FileInput>
      <div>{displayStatus}</div>
    </div>
  );
};

TusVideoInput.propTypes = {
  label: PropTypes.string,
  source: PropTypes.string.isRequired,
};

TusVideoInput.defaultProps = {
  label: '',
};

export default TusVideoInput;
