import React from 'react';
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';

const TextArrayField = ({ record, source }) => (
  <>
    {record[source].map((item, index) =>
      <Chip
        label={item}
        key={index}
      />
    )}
  </>
);
TextArrayField.defaultProps = { addLabel: true };
TextArrayField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string,
};

export default TextArrayField;