import React, {useCallback, useState} from 'react';
import {format as formatDate} from 'date-fns';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import PropTypes from 'prop-types';
import {
  BooleanField,
  BooleanInput,
  Create,
  CreateButton,
  Datagrid,
  DeleteWithConfirmButton,
  Edit,
  Filter,
  FunctionField,
  ImageField,
  List,
  UrlField,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar,
  useLogout,
  useNotify,
} from 'react-admin';
import { ResourcePagination } from './utils';

import TusVideoInput from './components/TusVideoInput';
import CloudflareVideoField from './components/CloudflareVideoField';
import RegenerateAttributionButton from './components/RegenerateAttributionButton';
import config from './config';
import {readAuthToken} from './utils';

const useStyles = makeStyles({
  unresolvedReport: {
    backgroundColor: 'rgba(255,0,0,0.25)',
  },
  resolvedReport: {
    backgroundColor: 'transparent',
  },
  reportsHeader: {
    '& th': {
      textAlign: 'left',
    },
  },
  reportsTable: {
    width: '100%',
  },
  reportsContainer: {
    width: '42%',
    margin: '1em',
    fontSize: 12,
  },
  reportReason: {
    maxWidth: '24em',
    overflow: 'auto',
  },
  listThumbnail: {maxWidth: 120, maxHeight: 120},
  thumbnail: {maxWidth: 300, maxHeight: 300},
  videoEditToolbarButtons: {
    flex: 1,
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
  },
  reportsCountContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
  },
  reportsCountIcon: {
    marginLeft: 6,
    fill: 'rgb(196, 79, 93)',
  },
});

const UserGeneratedVideoListActions = () => (
  <TopToolbar>
    <CreateButton />
  </TopToolbar>
);

const UserGeneratedVideoListFilters = (props) => (
  <Filter {...props}>
    <SelectInput
      allowEmpty
      alwaysOn
      source="hasUnresolvedReports"
      label="Unresolved reports"
      emptyText="All user videos"
      choices={[{id: true, name: 'Has Unresolved Reports'}]}
      style={{width: 360}}
    />
  </Filter>
);

export const UserGeneratedContentList = (props) => {
  const classes = useStyles();
  return (
    <List
      {...props}
      perPage={25}
      pagination={<ResourcePagination />}
      actions={<UserGeneratedVideoListActions />}
      sort={{field: 'createdAt', order: 'DESC'}}
      bulkActionButtons={false}
      filters={<UserGeneratedVideoListFilters />}
    >
      <Datagrid rowClick="edit">
        <ImageField
          source="thumbnail"
          classes={{
            image: classes.listThumbnail,
          }}
        />
        <TextField source="uid" />
        <TextField source="createdAt" />
        <BooleanField source="isReadyToStream" />
        <BooleanField source="isVisible" />
        <TextField source="id" sortable={false} />
        <TextField source="title" />
        <UrlField source="sharingSiteUrl" />

        <FunctionField
          label="Reports"
          render={(record) => {
            const reportsCount = record.reports.filter((report) => !report.isResolved).length;
            return (
              <div className={classes.reportsCountContainer}>
                <span>{`${reportsCount || 'None'}`}</span>
                {(reportsCount && <ErrorIcon color="red" className={classes.reportsCountIcon} />) ||
                  ''}
              </div>
            );
          }}
        />
      </Datagrid>
    </List>
  );
};

const RefreshButton = ({userVideoId}) => {
  const [isLoading, setIsLoading] = useState(false);
  const logout = useLogout();
  const notify = useNotify();
  const handleRefreshButtonClick = useCallback(async () => {
    setIsLoading(true);
    const token = readAuthToken(logout);
    const variables = {
      id: userVideoId,
    };
    const body = {
      query: `
      mutation($id: UUID!) {
        updateUserVideoCloudflareStatus(id: $id) {
          id
          url
          isReadyToStream
          meta {
            size
            pctComplete
          }
        }
      }
    `,
      variables,
    };
    try {
      await fetch(`${config.API_HOST}/graphql`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: new Headers({
          authorization: `Bearer ${token}`,
          'content-type': 'application/json',
        }),
      });
      setIsLoading(false);
      window.location.reload();
    } catch (error) {
      setIsLoading(false);
      if (error.graphQLErrors && error.graphQLErrors.length) {
        notify(error.graphQLErrors[0].message, 'error');
      } else if (error) {
        notify(error.message, 'error');
      }
    }
  }, [logout, notify, userVideoId]);
  return (
    <Button variant="contained" color="primary" onClick={handleRefreshButtonClick}>
      {isLoading ? 'Refreshing...' : 'Refresh Transcoding'}
    </Button>
  );
};

RefreshButton.propTypes = {
  userVideoId: PropTypes.string.isRequired,
};

const UserGeneratedVideoEditToolbar = (props) => {
  const classes = useStyles();
  const {
    record: {id: userVideoId},
  } = props;
  const logout = useLogout();
  const notify = useNotify();
  const handleHardDeleteButtonClick = useCallback(async () => {
    const token = readAuthToken(logout);
    const variables = {
      id: userVideoId,
    };
    const body = {
      query: `
      mutation($id: UUID!) {
        hardDeleteUGVideo(id: $id) {
          id
          url
          isReadyToStream
          meta {
            size
            pctComplete
          }
        }
      }
    `,
      variables,
    };
    try {
      const result = await fetch(`${config.API_HOST}/graphql`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: new Headers({
          authorization: `Bearer ${token}`,
          'content-type': 'application/json',
        }),
      });
      const response = await result.json();
      if (response?.errors?.length) {
        notify(response.errors[0].message, 'error');
      } else {
        notify('Successfully deleted UGC');
      }
      window.location.reload();
    } catch (error) {
      if (error.graphQLErrors && error.graphQLErrors.length) {
        notify(error.graphQLErrors[0].message, 'error');
      } else if (error) {
        notify(error.message, 'error');
      }
    }
  }, [logout, notify, userVideoId]);
  return (
    <Toolbar {...props} className={classes.videoEditToolbarButtons}>
      <SaveButton />
      <RefreshButton userVideoId={userVideoId} />
      <RegenerateAttributionButton userVideoId={userVideoId} />
      <DeleteWithConfirmButton confirmTitle='Are you sure you would like to delete this video?' confirmContent='Please confirm the action'  />
      <DeleteWithConfirmButton onClick={handleHardDeleteButtonClick} label='Hard delete' confirmTitle='Are you sure you would like to delete this video. This will permanently delete this content, and it will not be recoverable?' confirmContent='Please confirm the action'/>
    </Toolbar>
  );
};

UserGeneratedVideoEditToolbar.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

const UserGeneratedVideoCreateToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );
};

export const UserGeneratedVideoCreate = (props) => (
  <Create {...props}>
    <SimpleForm toolbar={<UserGeneratedVideoCreateToolbar />}>
      <TusVideoInput
        source="uid"
        label="User Video"
        placeholder="Select video"
        accept="video/*"
        validate={[required()]}
      />
    </SimpleForm>
  </Create>
);

const formatReportDate = (date) => formatDate(date, 'D MMM YY HH:mm:ss');

const UserGeneratedVideoEditAside = ({record}) => {
  const classes = useStyles();

  const logout = useLogout();
  const notify = useNotify();

  const ignoreReport = useCallback(
    async (report) => {
      const confirmMessage = `Ignore "${report.reason}" report from ${formatReportDate(
        report.createdAt
      )}?`;
      if (!confirm(confirmMessage)) {
        return;
      }

      const token = readAuthToken(logout);
      const variables = {
        input: {
          id: report.id,
          isResolved: true,
          resolution: 'Ignored',
        },
      };
      const body = {
        query: `
          mutation (
            $input: UpdateUserVideoReportInput!
          ) {
            updateUserVideoReport (
                input: $input
            ) {
              id
              reason
              isResolved
              resolution
            }
          }
        `,
        variables,
      };
      const response = await fetch(`${config.API_HOST}/graphql`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: new Headers({
          authorization: `Bearer ${token}`,
          'content-type': 'application/json',
        }),
      });

      if (response.status !== 200 && response.status !== 400) {
        notify(response.statusText);
        return;
      }

      let responseBody;
      try {
        responseBody = await response.json();
      } catch (e) {
        notify(e.getMessage());
        if (e.getMessage() === 'Unauthorized request') {
          logout();
        }
      }

      if (responseBody.errors) {
        const error = responseBody.errors[0];
        notify(error.message, 'warning');
        return;
      }

      notify(`Ignored report ${report.reason}`, 'success');
      window.location.reload();
    },

    [logout, notify]
  );

  if (!record) {
    return null;
  }

  return (
    <div className={classes.reportsContainer}>
      <Typography variant="h6">User Video Reports</Typography>
      {record.reports?.length && (
        <table className={classes.reportsTable}>
          <thead>
            <tr className={classes.reportsHeader}>
              <th>Date</th>
              <th>Reason</th>
              <th>Resolution</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {record.reports.map((report) => (
              <tr
                key={report.id}
                className={!report.isResolved ? classes.unresolvedReport : undefined}
              >
                <td>{formatReportDate(report.createdAt)}</td>
                <td className={classes.reportReason}>{report.reason}</td>
                <td>{report.resolution}</td>
                <td>
                  {!report.isResolved ? (
                    <button onClick={() => ignoreReport(report)}>Ignore</button>
                  ) : null}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

UserGeneratedVideoEditAside.propTypes = {
  record: PropTypes.object,
};

UserGeneratedVideoEditAside.defaultProps = {
  record: undefined,
};

const UserGeneratedVideoPromotionAction = ({data}) => {
  const logout = useLogout();
  const notify = useNotify();
  const [targetEnvUrl, setTargetEnvUrl] = useState('');
  const isPromoteButtonDisabled = !targetEnvUrl;

  const handleTargetEnvUrlChange = useCallback(
    (e) => {
      setTargetEnvUrl(e.target.value);
    },
    [setTargetEnvUrl]
  );

  const hasPromoteTargets = config.PROMOTE_TARGETS.length > 0;
  const promoteTargetNames = config.PROMOTE_TARGET_NAMES;

  const customAction = useCallback(
    async (userVideo) => {
      if (
        window.confirm(`Are you sure you want to promote ${userVideo.id} to ${targetEnvUrl}?`)
      ) {
        const token = readAuthToken(logout);
        const variables = {
          id: userVideo.id,
          targetEnvUrl,
        };
        const body = {
          query: `
          mutation (
            $id: UUID!, $targetEnvUrl: String!
          ) {
            promoteUserVideo (
                id: $id, targetEnvUrl: $targetEnvUrl
            ) {
              currentVideo {
                id, url, isFeatured, isReadyToStream, thumbnail, sortNumber
              },
              promotedVideo {
                id, url, isFeatured, isReadyToStream, thumbnail, sortNumber
              }
            }
          }
        `,
          variables,
        };
        const response = await fetch(`${config.API_HOST}/graphql`, {
          method: 'POST',
          body: JSON.stringify(body),
          headers: new Headers({
            authorization: `Bearer ${token}`,
            'content-type': 'application/json',
          }),
        });
        setTargetEnvUrl('');

        if (response.status !== 200 && response.status !== 400) {
          notify(response.statusText);
          return;
        }

        let responseBody;
        try {
          responseBody = await response.json();
        } catch (e) {
          notify(e.getMessage());
          if (e.getMessage() === 'Unauthorized request') {
            logout();
          }
        }

        if (responseBody.errors) {
          const error = responseBody.errors[0];
          notify(error.message, 'warning');
          return;
        }

        const {data: responseData} = responseBody;
        const promotedUserVideoId = responseData?.promoteUserVideo?.promotedVideo?.id || null;

        notify(`Successfully promoted ${userVideo.id} (was: ${promotedUserVideoId})`, 'success');
      }
    },
    [logout, notify, targetEnvUrl]
  );

  if (!data) {
    return null;
  }

  return (
    <TopToolbar>
      {hasPromoteTargets && (
        <Select native style={{marginLeft: 15}} onChange={handleTargetEnvUrlChange}>
          <option value="">Select Environment</option>
          {config.PROMOTE_TARGETS.map((promoteTarget, promoteTargetIndex) => (
            <option key={promoteTarget} value={promoteTarget}>
              {promoteTargetNames[promoteTargetIndex] || promoteTarget}
            </option>
          ))}
        </Select>
      )}
      {hasPromoteTargets && (
        <Button
          color="primary"
          onClick={() => customAction(data)}
          style={{
            padding: '4px 5px',
            fontSize: '0.8125rem',
            marginLeft: 5,
          }}
          disabled={isPromoteButtonDisabled}
        >
          Promote {data.id}
        </Button>
      )}
    </TopToolbar>
  );
};

UserGeneratedVideoPromotionAction.propTypes = {
  basePath: PropTypes.string,
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
};

export const UserGeneratedContentEdit = (props) => {
  return (
    <Edit {...props} actions={<UserGeneratedVideoPromotionAction />} aside={<UserGeneratedVideoEditAside />}>
      <SimpleForm toolbar={<UserGeneratedVideoEditToolbar />}>
        <TextInput disabled source="id" />
        <TextField source="uid" />
        <TextField source="state" />
        <TextField source="meta.pctComplete" label="% Complete" />
        <BooleanField source="isReadyToStream" />
        <ImageField source="thumbnail" />
        <CloudflareVideoField source="uid" />
        <UrlField source="sharingSiteUrl" />
        <BooleanInput source="isVisible" />
        <TextInput source="title" />
        <TextField source="sharingMeta.character" />
        <TextField source="sharingMeta.background" />
        <TextField source="sharingMeta.destination" />
      </SimpleForm>
    </Edit>
  );
};
