import React from 'react';
import {format as formatDateFns} from 'date-fns';
import {Pagination} from 'react-admin';

export const ResourcePagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

export const formatDate = (date, requestedFormat) => {
  let format = requestedFormat || 'D MMM YY HH:mm:ss';
  if (requestedFormat === 'short') {
    format = 'MMM D HH:mm';
  }
  return formatDateFns(date, format);
};

export const readAuthToken = (logout) => {
  try {
    const json = JSON.parse(localStorage.getItem('auth'));
    return json.accessToken;
  } catch (e) {
    console.error('Error parsing access token', e);
    logout();
  }
  return null;
};

const convertBytes = function(bytes) {
  const sizes = ['Bytes', 'KB', 'MB'];
  const magnitude = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));

  if (magnitude === 0) {
    return bytes + ' ' + sizes[magnitude];
  }
  return (bytes / Math.pow(1024, magnitude)).toFixed(1) + ' ' + sizes[magnitude];
};

export const maxReferenceFileSize = (maxSizeInBytes) => (value) => {
  if (value && value !== null) {
    if (value && typeof value !== 'object' || !value.meta) {
      // this means no new file was uploaded
      return undefined;
    }
    if (!value.meta.size) {
      return 'Uploaded file size unknown or 0';
    }
    if (value.meta.size > maxSizeInBytes) {
      return `Uploaded file size must not be bigger than ${convertBytes(maxSizeInBytes)}`;
    }
  }
  return undefined;
};

export const requiredReferenceId = () => (value) => {
  if (value && value.rawFile) {
    return 'File is not yet uploaded';
  }
  return undefined;
};