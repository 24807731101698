import React, { useCallback, useMemo, useState } from 'react';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MuiTextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  Create,
  BooleanField,
  BooleanInput,
  Datagrid,
  Edit,
  EditButton,
  Filter,
  FunctionField,
  ImageField,
  List,
  maxLength,
  minLength,
  minValue,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  required,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  UrlField,
  useLogout,
  useMutation,
  useRedirect,
  useNotify,
  ArrayInput,
  SimpleFormIterator,
} from 'react-admin';
import { ColorField, ColorInput } from 'react-admin-color-input';

import ReferenceFileDirectInput from './components/ReferenceFileDirectInput';
import TextArrayField from './components/TextArrayField';
import VideoField from './components/VideoPreviewField';
import config from './config';
import { useGenerateNewCode, useToggleCode } from './hooks/character.hooks';
import { formatDate, readAuthToken, requiredReferenceId, maxReferenceFileSize, ResourcePagination } from './utils';

const NAME_MIN_LENGTH = 2;
const NAME_MAX_LENGTH = 23;
const BIO_MAX_LENGTH = 280;
const MODEL_MAX_FILE_SIZE_BYTES = 314572800;
const THUMBNAIL_MAX_FILE_SIZE_BYTES = 1048576;
const RECENT_THUMBNAIL_MAX_FILE_SIZE_BYTES = 1048576;
const PREVIEW_MAX_FILE_SIZE_BYTES = 10485760;
const LOADING_VIDEO_MAX_FILE_SIZE_BYTES = 10485760;
const VIDEO_PREVIEW_MAX_FILE_SIZE_BYTES = 314572800;
const DEFAULT_SCALE_MIN = 0.01;
const WALKING_SPEED_MIN = 0.00001;
const RUNNING_SPEED_MIN = 0.00001;

const useStyles = makeStyles({
  codeActivated: {
    backgroundColor: '#e5f7d5',
  },
  codeDisabled: {
    backgroundColor: '#f7d5e1',
  },
  codesContainer: {
    width: '50%',
    margin: '1em',
    fontSize: 12,
  },
  codesHeader: {
    '& th': {
      textAlign: 'left',
    },
  },
  codesTable: {
    width: '100%',
  },
  generateCodeForm: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignContent: 'baseline',
    marginBottom: '2em',
  },
  generateCodeLabel: {
    flex: 1,
  },
  generateCodeButton: {
    display: 'block',
    marginLeft: '1em',
  },
  listThumbnailImage: { maxWidth: 60, maxHeight: 60 },
});

const useSave = () => {
  const [mutate] = useMutation();
  const notify = useNotify();
  const redirect = useRedirect();
  return useCallback(
    async (values) => {
      try {
        if (typeof values.enabled === 'undefined') {
          values.enabled = false;
        }
        if (typeof values.controlled === 'undefined') {
          values.controlled = false;
        }
        const response = await mutate(
          {
            type: values.id ? 'update' : 'create',
            resource: 'Character',
            payload: { data: values },
          },
          { returnPromise: true }
        );
        const {
          data: { id: characterId },
        } = response;
        redirect(`/Character/${characterId}/show`);
      } catch (error) {
        if (error.graphQLErrors && error.graphQLErrors.length) {
          if (error.graphQLErrors[0].message.indexOf('already taken') > 0) {
            return {
              name: error.graphQLErrors[0].message,
            };
          } else {
            notify(error.graphQLErrors[0].message, 'error');
          }
        } else if (error) {
          notify(error.message, 'error');
        }
      }
    },
    [mutate, notify, redirect]
  );
};

export const CharacterCreate = (props) => {
  const save = useSave();
  return (
    <Create {...props}>
      <SimpleForm save={save}>
        <BooleanInput label="Is Enabled?" source="enabled" />
        <BooleanInput label="Is Controlled?" source="controlled" />
        <BooleanInput label="Is Featured?" source="featured" />
        <BooleanInput label="Use toon shader?" source="toonShader" />
        <BooleanInput label="Is Buyable?" source="buyable" />
        <BooleanInput label="Protect from deletion by sync script?" source="protectFromDeletionDuringSync" />
        <TextInput
          source="name"
          validate={[required(), minLength(NAME_MIN_LENGTH), maxLength(NAME_MAX_LENGTH)]}
        />
        <TextInput
          source="bio"
          multiline
          style={{ width: 420 }}
          validate={[maxLength(BIO_MAX_LENGTH)]}
        />
        <ReferenceInput label="Category" source="category.id" reference="Category">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <NumberInput source="sortNumber" />
        <ReferenceFileDirectInput
          source="model"
          label="Model Archive"
          referenceSource="modelId"
          reference="File"
          placeholder="Select model Archive"
          accept="application/zip,application/x-7z-compressed"
          folder="models"
          validate={[
            required(),
            requiredReferenceId(),
            maxReferenceFileSize(MODEL_MAX_FILE_SIZE_BYTES),
          ]}
        />
        <ReferenceFileDirectInput
          image
          source="thumbnail"
          label="Thumbnail Image"
          referenceSource="thumbnailId"
          reference="File"
          placeholder="Select thumbnail image"
          accept="image/jpeg,image/jpg,image/png"
          folder="images"
          validate={[
            required(),
            requiredReferenceId(),
            maxReferenceFileSize(THUMBNAIL_MAX_FILE_SIZE_BYTES),
          ]}
        />
        <ReferenceFileDirectInput
          image
          source="recentThumbnail"
          label="Recent Thumbnail Image"
          referenceSource="recentThumbnailId"
          reference="File"
          placeholder="Select recent thumbnail image"
          accept="image/jpeg,image/jpg,image/png"
          folder="images"
          validate={[
            required(),
            requiredReferenceId(),
            maxReferenceFileSize(RECENT_THUMBNAIL_MAX_FILE_SIZE_BYTES),
          ]}
        />
        <ReferenceFileDirectInput
          image
          source="preview"
          label="Preview Image"
          referenceSource="previewId"
          reference="File"
          placeholder="Select preview image"
          accept="image/jpeg,image/jpg,image/png"
          folder="images"
          validate={[
            required(),
            requiredReferenceId(),
            maxReferenceFileSize(PREVIEW_MAX_FILE_SIZE_BYTES),
          ]}
        />
        <ReferenceFileDirectInput
          source="loadingVideo"
          folder="images"
          label="Loading Preview"
          referenceSource="loadingVideoId"
          reference="File"
          placeholder="Select video to display while loading"
          accept="video/quicktime,video/mp4,video/mpeg,application/mp4"
          validate={[
            requiredReferenceId(),
            maxReferenceFileSize(VIDEO_PREVIEW_MAX_FILE_SIZE_BYTES),
          ]}
        />
        <ReferenceFileDirectInput
          source="loadingBackground"
          folder="images"
          label="Loading background"
          referenceSource="loadingBackgroundId"
          reference="File"
          placeholder="Select the background for the loading screen"
          accept="image/jpeg,image/jpg,image/png"
          validate={[
            requiredReferenceId(),
            maxReferenceFileSize(LOADING_VIDEO_MAX_FILE_SIZE_BYTES),
          ]}
        />
        <NumberInput source="defaultScale" validate={[minValue(DEFAULT_SCALE_MIN)]} />
        <NumberInput source="superNeutralDistanceFromHead" />
        <NumberInput source="eyeLockingMaxAngleFromEyes" />
        <NumberInput source="walkingSpeed" validate={[minValue(WALKING_SPEED_MIN)]} />
        <NumberInput source="runningSpeed" validate={[minValue(RUNNING_SPEED_MIN)]} />
        <TextInput source="contractAddress" />
        <TextInput source="tokenId" />
        <TextInput source="ipfsUrl" />
        <TextInput source="faction" />
        <TextInput source="iapId" />
        <ColorInput
          source="loadingBackgroundColor"
        />
        <ArrayInput source="bios">
          <SimpleFormIterator>
            <TextInput />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};

export const CharacterEdit = (props) => {
  const save = useSave();
  return (
    <Edit {...props}>
      <SimpleForm save={save}>
        <TextInput disabled label="Id" source="id" />
        <BooleanInput label="Is Enabled?" source="enabled" />
        <BooleanInput label="Is Controlled?" source="controlled" />
        <BooleanInput label="Is Featured?" source="featured" />
        <BooleanInput label="Use toon shader?" source="toonShader" />
        <BooleanInput label="Is Buyable?" source="buyable" />
        <BooleanInput label="Protect from deletion by sync script?" source="protectFromDeletionDuringSync" />
        <TextInput
          source="name"
          validate={[required(), minLength(NAME_MIN_LENGTH), maxLength(NAME_MAX_LENGTH)]}
        />
        <TextInput
          source="bio"
          multiline
          style={{ width: 420 }}
          validate={[maxLength(BIO_MAX_LENGTH)]}
        />
        <ReferenceInput label="Category" source="category.id" reference="Category">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <NumberInput source="sortNumber" step={1} />
        <ReferenceFileDirectInput
          folder="models"
          source="model"
          label="Model Archive"
          referenceSource="modelId"
          reference="File"
          placeholder="Select model Archive"
          accept="application/zip,application/x-7z-compressed"
          validate={[
            required(),
            requiredReferenceId(),
            maxReferenceFileSize(MODEL_MAX_FILE_SIZE_BYTES),
          ]}
        />
        <ReferenceFileDirectInput
          image
          folder="images"
          source="thumbnail"
          label="Thumbnail Image"
          referenceSource="thumbnailId"
          reference="File"
          placeholder="Select thumbnail image"
          accept="image/jpeg,image/jpg,image/png"
          validate={[
            required(),
            requiredReferenceId(),
            maxReferenceFileSize(THUMBNAIL_MAX_FILE_SIZE_BYTES),
          ]}
        />
        <ReferenceFileDirectInput
          image
          source="recentThumbnail"
          label="Recent Thumbnail Image"
          referenceSource="recentThumbnailId"
          reference="File"
          placeholder="Select recent thumbnail image"
          accept="image/jpeg,image/jpg,image/png"
          folder="images"
          validate={[
            required(),
            requiredReferenceId(),
            maxReferenceFileSize(RECENT_THUMBNAIL_MAX_FILE_SIZE_BYTES),
          ]}
        />
        <ReferenceFileDirectInput
          image
          folder="images"
          source="preview"
          label="Preview Image"
          referenceSource="previewId"
          reference="File"
          placeholder="Select preview image"
          accept="image/jpeg,image/jpg,image/png"
          validate={[
            required(),
            requiredReferenceId(),
            maxReferenceFileSize(PREVIEW_MAX_FILE_SIZE_BYTES),
          ]}
        />
        <ReferenceFileDirectInput
          source="loadingVideo"
          folder="images"
          label="Loading Preview"
          referenceSource="loadingVideoId"
          reference="File"
          placeholder="Select video to display while loading"
          accept="video/quicktime,video/mp4,video/mpeg,application/mp4"
          validate={[
            requiredReferenceId(),
            maxReferenceFileSize(VIDEO_PREVIEW_MAX_FILE_SIZE_BYTES),
          ]}
        />
        <ReferenceFileDirectInput
          source="loadingBackground"
          folder="images"
          label="Loading background"
          referenceSource="loadingBackgroundId"
          reference="File"
          placeholder="Select the background for the loading screen"
          accept="image/jpeg,image/jpg,image/png"
          validate={[
            requiredReferenceId(),
            maxReferenceFileSize(LOADING_VIDEO_MAX_FILE_SIZE_BYTES),
          ]}
        />
        <ReferenceFileDirectInput
          source="loadingFallbackImage"
          folder="images"
          label="Loading fallback image"
          referenceSource="loadingFallbackImageId"
          reference="File"
          placeholder="Select the fallback image to display if no video is provided"
          accept="image/jpeg,image/jpg,image/png"
          validate={[
            maxReferenceFileSize(LOADING_VIDEO_MAX_FILE_SIZE_BYTES),
          ]}
        />
        <NumberInput source="defaultScale" validate={[minValue(DEFAULT_SCALE_MIN)]} />
        <NumberInput source="superNeutralDistanceFromHead" />
        <NumberInput source="eyeLockingMaxAngleFromEyes" />
        <NumberInput source="walkingSpeed" validate={[minValue(WALKING_SPEED_MIN)]} />
        <NumberInput source="runningSpeed" validate={[minValue(RUNNING_SPEED_MIN)]} />
        <TextInput
          source="contractAddress"
        />
        <TextInput
          source="tokenId"
        />
        <TextInput
          source="ipfsUrl"
        />
        <TextInput source="faction" />
        <TextInput source="iapId" />
        <ColorInput
          source="loadingBackgroundColor"
        />
        <ArrayInput source="bios">
          <SimpleFormIterator>
            <TextInput />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};

const CharacterFilters = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    {/* <BooleanInput source="isEnabled" /> */}
    <SelectInput
      source="isEnabled"
      allowEmpty
      emptyText="All"
      choices={[
        { id: true, name: 'Enabled' },
        { id: false, name: 'Disabled' },
      ]}
    />
    <SelectInput
      source="isControlled"
      allowEmpty
      emptyText="All"
      choices={[
        { id: true, name: 'Enabled' },
        { id: false, name: 'Disabled' },
      ]}
    />
    <SelectInput
      source="ipfsUrl"
      label="Is an NFT"
      emptyText="All"
      choices={[
        { id: true, name: 'Yes ' },
        { id: false, name: 'No' },
      ]}
    />
    <ReferenceInput label="Category" source="category.id" reference="Category">
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

export const CharacterList = (props) => {
  const classes = useStyles();
  return (
    <List
      {...props}
      perPage={25}
      pagination={<ResourcePagination />}
      sort={{ field: 'name', order: 'ASC' }}
      bulkActionButtons={false}
      filters={<CharacterFilters />}
    >
      <Datagrid rowClick="show">
        <ReferenceField source="thumbnail.id" reference="File" label="Thumbnail" link={false} sortable={false}>
          <ImageField
            source="url"
            classes={{
              image: classes.listThumbnailImage,
            }}
          />
        </ReferenceField>
        <TextField source="name" />
        <BooleanField source="enabled" />
        <BooleanField source="controlled" />
        <FunctionField
          label="Promoted On"
          render={(record) => {
            if (!record?.promotions?.length) {
              return '—';
            }
            return formatDate(
              record.promotions.sort((b, a) => new Date(a.createdAt) - new Date(b.createdAt))[0]
                .createdAt,
              'short'
            );
          }}
        />
        <ReferenceField source="category.id" reference="Category" label="Category" link={false} sortBy="categoryName">
          <TextField source="name" />
        </ReferenceField>
        <NumberField source="sortNumber" />
        <TextField source="updatedAt" />
        <TextField source="createdAt" />
        <TextField source="id"  sortable={false}/>
        <NumberField source="defaultScale" />
        <NumberField source="walkingSpeed" />
        <NumberField source="runningSpeed" />
        <TextField source="faction"  sortable={false} />
      </Datagrid>
    </List>
  );
};

const CharacterShowActions = ({ basePath, data }) => {
  const logout = useLogout();
  const notify = useNotify();
  const [targetEnvUrl, setTargetEnvUrl] = useState('');
  const isPromoteButtonDisabled = !targetEnvUrl;

  const handleTargetEnvUrlChange = useCallback(
    (e) => {
      setTargetEnvUrl(e.target.value);
    },
    [setTargetEnvUrl]
  );

  const hasPromoteTargets = config.PROMOTE_TARGETS.length > 0;
  const promoteTargetNames = config.PROMOTE_TARGET_NAMES;

  const customAction = useCallback(
    async (character) => {
      if (
        window.confirm(`Are you sure you want to promote ${character.name} to ${targetEnvUrl}?`)
      ) {
        const token = readAuthToken(logout);
        const variables = {
          id: character.id,
          targetEnvUrl,
        };
        const body = {
          query: `
          mutation (
            $id: UUID!, $targetEnvUrl: String!
          ) {
            promoteCharacter (
                id: $id, targetEnvUrl: $targetEnvUrl
            ) {
              previousValues {
                id, name, bio, enabled, model { url }, createdAt,
                eyeLockingMaxAngleFromEyes, superNeutralDistanceFromHead
              }
            }
          }
        `,
          variables,
        };
        const response = await fetch(`${config.API_HOST}/graphql`, {
          method: 'POST',
          body: JSON.stringify(body),
          headers: new Headers({
            authorization: `Bearer ${token}`,
            'content-type': 'application/json',
          }),
        });
        setTargetEnvUrl('');

        if (response.status !== 200 && response.status !== 400) {
          notify(response.statusText);
          return;
        }

        let responseBody;
        try {
          responseBody = await response.json();
        } catch (e) {
          notify(e.getMessage());
          if (e.getMessage() === 'Unauthorized request') {
            logout();
          }
        }

        if (responseBody.errors) {
          const error = responseBody.errors[0];
          notify(error.message, 'warning');
          return;
        }

        const { data: responseData } = responseBody;
        const previousName = responseData?.promoteCharacter?.previousValues?.name || null;

        notify(`Successfully promoted ${character.name} (was: ${previousName})`, 'success');
      }
    },
    [logout, notify, targetEnvUrl]
  );

  if (!data) {
    return null;
  }

  return (
    <TopToolbar>
      <EditButton basePath={basePath} record={data} />
      {hasPromoteTargets && (
        <Select native style={{ marginLeft: 15 }} onChange={handleTargetEnvUrlChange}>
          <option value="">Select Environment</option>
          {config.PROMOTE_TARGETS.map((promoteTarget, promoteTargetIndex) => (
            <option key={promoteTarget} value={promoteTarget}>
              {promoteTargetNames[promoteTargetIndex] || promoteTarget}
            </option>
          ))}
        </Select>
      )}
      {hasPromoteTargets && (
        <Button
          color="primary"
          onClick={() => customAction(data)}
          style={{
            padding: '4px 5px',
            fontSize: '0.8125rem',
            marginLeft: 5,
          }}
          disabled={isPromoteButtonDisabled}
        >
          Promote {data.name}
        </Button>
      )}
    </TopToolbar>
  );
};

CharacterShowActions.propTypes = {
  basePath: PropTypes.string,
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
};

const CharacterShowAside = ({ record }) => {
  const classes = useStyles();

  const characterId = record?.id;
  const [isLoading, setIsLoading] = useState(false);
  const [newCodeLabel, setNewCodeLabel] = useState('');

  const toggleCode = useToggleCode(setIsLoading);
  const generateNewCode = useGenerateNewCode(
    characterId,
    newCodeLabel,
    setNewCodeLabel,
    setIsLoading
  );

  const codes = useMemo(
    () => (record?.codes || []).sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)),
    [record?.codes]
  );

  if (!record) {
    return null;
  }

  return (
    <div className={classes.codesContainer}>
      <Typography variant="h6">Character Secret Codes</Typography>
      <div className={classes.generateCodeForm}>
        <MuiTextField
          className={classes.generateCodeLabel}
          label="Secret Code Label (Optional)"
          onChange={(event) => setNewCodeLabel(event.target.value)}
          value={newCodeLabel}
        />
        <button
          enabled={!isLoading}
          className={classes.generateCodeButton}
          onClick={generateNewCode}
        >
          Generate New Code
        </button>
      </div>
      {codes.length && (
        <table className={classes.codesTable}>
          <thead>
            <tr className={classes.codesHeader}>
              <th>Label</th>
              <th>Value</th>
              <th>Activated At</th>
              <th>Disabled At</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {codes.map((code) => (
              <tr
                key={code.id}
                className={classnames({
                  [classes.codeActivated]: !!code.activatedAt,
                  [classes.codeDisabled]: code.disabledAt,
                })}
              >
                <td>{code.label}</td>
                <td>{code.value}</td>
                <td>{code.activatedAt ? formatDate(code.activatedAt, 'short') : '—'}</td>
                <td>{code.disabledAt ? formatDate(code.disabledAt, 'short') : '—'}</td>
                <td>
                  <button onClick={() => toggleCode(code.id, !code.disabledAt)}>
                    {code.disabledAt ? 'Enable' : 'Disable'}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {!codes.length && <div>No secret codes for this character</div>}
    </div>
  );
};

CharacterShowAside.propTypes = {
  record: PropTypes.object,
};

CharacterShowAside.defaultProps = {
  record: undefined,
};

export const CharacterShow = (props) => (
  <Show {...props} actions={<CharacterShowActions />} aside={<CharacterShowAside />}>
    <SimpleShowLayout>
      <BooleanField label="Is Enabled?" source="enabled" />
      <BooleanField label="Is Controlled?" source="controlled" />
      <BooleanField label="Is Featured?" source="featured" />
      <BooleanField label="Use toon shader?" source="toonShader" />
      <FunctionField
        label="Promoted On"
        render={(record) => {
          if (!record?.promotions?.length) {
            return '—';
          }
          return formatDate(
            record.promotions.sort((b, a) => new Date(a.createdAt) - new Date(b.createdAt))[0]
              .createdAt
          );
        }}
      />
      <TextField source="name" />
      <TextField source="bio" />
      <ReferenceField source="category.id" reference="Category" label="Category" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="sortNumber" />
      <ReferenceField source="model.id" reference="File" label="Model Archive" link={false}>
        <UrlField source="url" />
      </ReferenceField>
      <ReferenceField source="thumbnail.id" reference="File" label="Thumbnail" link={false}>
        <ImageField source="url" />
      </ReferenceField>
      <ReferenceField source="recentThumbnail.id" reference="File" label="Recent Thumbnail" link={false}>
        <ImageField source="url" />
      </ReferenceField>
      <ReferenceField source="preview.id" reference="File" label="Preview" link={false}>
        <ImageField source="url" />
      </ReferenceField>
      <ReferenceField source="loadingVideo.id" reference="File" label="Loading Video" link={false}>
        <VideoField source="url" />
      </ReferenceField>
      <ReferenceField source="loadingBackground.id" reference="File" label="Loading Background" link={false}>
        <ImageField source="url" />
      </ReferenceField>
      <ReferenceField source="loadingFallbackImage.id" reference="File" label="Loading Fallback Image" link={false}>
        <ImageField source="url" />
      </ReferenceField>
      <ColorField
        source="loadingBackgroundColor"
      />
      <NumberField source="defaultScale" />
      <NumberField source="superNeutralDistanceFromHead" />
      <NumberField source="eyeLockingMaxAngleFromEyes" />
      <NumberField source="walkingSpeed" />
      <NumberField source="runningSpeed" />
      <TextField source="contractAddress" label="NFT Contract Address" />
      <TextField source="tokenId" label="NFT Token"/>
      <UrlField source="ipfsUrl" label="NFT IPFS URL"/>
      <TextField source="faction" label="NFT Faction"/>
      <TextArrayField source="bios" />
    </SimpleShowLayout>
  </Show>
);
