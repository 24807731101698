import {ApolloClient, InMemoryCache, gql} from '@apollo/client';

import config from './config';

const client = new ApolloClient({
  uri: `${config.API_HOST}/graphql`,
  cache: new InMemoryCache(),
});

const checkAndRefreshToken = async (authJson) => {
  const {
    accessToken: currentAccessToken,
    accessTokenExpiresAt: currentAccessTokenExpiresAt,
    refreshToken: currentRefreshToken,
  } = authJson;

  const now = Math.floor(Date.now() / 1000);
  if (now < currentAccessTokenExpiresAt) {
    return currentAccessToken;
  }

  let result;

  try {
    result = await client.mutate({
      mutation: gql`
        mutation ($token: String!) {
          refresh(token: $token) {
            accessToken
            accessTokenExpiresAt
            refreshToken
          }
        }
      `,
      variables: {token: currentRefreshToken},
    });
  } catch (e) {
    if (e.message === 'Refresh token invalid or expired') {
      console.warn(e.message);
    } else {
      console.error(e.message);
    }
    localStorage.removeItem('auth');
    return '';
  }

  if (!result?.data?.refresh?.accessToken) {
    throw new Error('Error refreshing token');
  }
  const {accessToken, accessTokenExpiresAt, refreshToken} = result.data.refresh;

  localStorage.setItem(
    'auth',
    JSON.stringify({
      accessTokenExpiresAt,
      accessToken,
      refreshToken,
    })
  );

  return accessToken;
};

export default {
  checkAndRefreshToken,

  login: async ({username, password}) => {
    const result = await client.mutate({
      mutation: gql`
        mutation ($email: String!, $password: String!) {
          login(email: $email, password: $password) {
            accessToken
            accessTokenExpiresAt
            refreshToken
            id
          }
        }
      `,
      variables: {email: username, password},
    });
    if (!result?.data?.login?.accessToken) {
      throw new Error('Error signing in');
    }
    const {accessToken, accessTokenExpiresAt, refreshToken} = result.data.login;
    localStorage.setItem(
      'auth',
      JSON.stringify({
        accessTokenExpiresAt,
        accessToken,
        refreshToken,
      })
    );
  },
  checkError: (error) => {
    if (
      error &&
      (error.message.indexOf('Unauthorized request') > 0 ||
        error.message === 'Refresh token invalid or expired')
    ) {
      localStorage.removeItem('auth');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: async () => {
    const auth = localStorage.getItem('auth');
    if (!auth) {
      return Promise.reject();
    }
    return Promise.resolve();
  },
  logout: () => {
    localStorage.removeItem('auth');
    return Promise.resolve();
  },
  // getIdentity: () => Promise.resolve(),
  getPermissions: () => Promise.resolve(),
};
