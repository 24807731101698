import React, {useCallback, useState} from 'react';

import PropTypes from 'prop-types';
import {FileField, FileInput, ImageField, ReferenceField, UrlField, useNotify} from 'react-admin';
import {useForm, useFormState} from 'react-final-form';

import {useS3Upload} from '../hooks/file.hooks';

const ReferenceFileDirectInput = (props) => {
  const form = useForm();
  const formState = useFormState();
  const notify = useNotify();
  const s3Upload = useS3Upload();
  const [isLoading, setIsLoading] = useState(false);
  const [isOriginalFileDeleted, setIsOriginalFileDeleted] = useState(false);
  const {folder} = props;

  const {values} = formState;

  const startUpload = useCallback(
    async (file) => {
      const result = await s3Upload(file, folder);
      return result;
    },
    [folder, s3Upload]
  );

  const handleFileChange = useCallback(
    async (file) => {
      let fileToUpload = file;
      setIsOriginalFileDeleted(true);

      if (!file) {
        // file was deleted
        form.change(props.source, {id: null});
        return null;
      }

      if (file && !file.type) {
        if (file.path.includes('.ttf')) {
          fileToUpload = new File([file], file.path, { type: 'font/ttf' });
        }
        if (file.path.includes('.otf')) {
          fileToUpload = new File([file], file.path, { type: 'font/otf' });
        }
      }

      setIsLoading(true);
      const createdFile = await startUpload(fileToUpload);
      setIsLoading(false);
      if (!createdFile) {
        return;
      }
      notify('File uploaded');
      form.change(props.source, createdFile);
    },
    [form, notify, props.source, startUpload]
  );

  const uploadedFile = values && values[`${props.source}`];
  const uploadedFileName = uploadedFile ? uploadedFile?.url : '';

  const fileExistsInRecord = !!values[`${props.source}.id`];
  const mustRenderReference = fileExistsInRecord && !isOriginalFileDeleted;

  return (
    <div style={{padding: 12, background: '#f0f0f0', marginBottom: 12, display: 'inline-block'}}>
      <FileInput onChange={handleFileChange} {...props}>
        {isLoading ? (
          <span>Loading...</span>
        ) : props.image ? (
          <ImageField source="url" />
        ) : (
          <FileField source="url" title={uploadedFileName} />
        )}
      </FileInput>
      {mustRenderReference && (
        <ReferenceField reference={props.reference} source={`${props.source}.id`} link={false}>
          {props.image ? <ImageField source="url" /> : <UrlField source="url" />}
        </ReferenceField>
      )}
    </div>
  );
};

ReferenceFileDirectInput.propTypes = {
  folder: PropTypes.string,
  image: PropTypes.bool,
  label: PropTypes.string,
  reference: PropTypes.string.isRequired,
  referenceSource: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
};

ReferenceFileDirectInput.defaultProps = {
  folder: undefined,
  image: false,
  label: '',
};

export default ReferenceFileDirectInput;
