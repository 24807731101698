import { gql } from '@apollo/client';

import { buildQuery } from 'ra-data-graphql-simple';

const UGVideoType = `
                id
                url
                thumbnail
                state
                uid
                sortNumber
                isReadyToStream
                isFeatured
                isVisible
                videoType
                sharingSiteUrl
                title
                createdAt
                sharingMeta {
                  character
                  background
                  destination
                }
                uploadedBy {
                  id
                  createdAt
                  updatedAt
                }
                reports {
                  id
                  reason
                  createdBy
                  isResolved
                  resolution
                  createdAt
                }
                meta {
                  size
                  pctComplete
                  errorReasonCode
                  errorReasonText
                }`;

export const videoQueryHandler = introspection => (fetchType, resource, params) => {
  if (resource === 'UGVideo' && fetchType === 'GET_ONE') {
    return {
      variables: params,
      parseResponse: (response) => {
        return { data: response.data.item };
      },
      query: gql`
          query UGVideo($id: UUID!) {
            item: UserVideo(id: $id) {
${UGVideoType}
            }
          }`,
    };
  }
  if (resource === 'UGVideo' && fetchType === 'GET_LIST') {
    params.pagination.page--;
    const variables = {
      ...params.pagination,
      sortOrder: params.sort.order,
      sortField: params.sort.field,
      filter: {
        ...params.filter,
        videoType: 'UGC_VIDEO',
      }
    };
    return {
      variables,
      parseResponse: (response) => {
        return {
          data: response.data.items,
          total: response.data.total.count,
        };
      },
      query: gql`
          query allUserVideos($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: UserVideoFilter) {
            items: allUserVideos(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) {
${UGVideoType}
            }
            total: _allUserVideosMeta(page: $page, perPage: $perPage, filter: $filter) {
              count
              __typename
            }
          }`,
    };
  }

  if (resource === 'UGVideo' && fetchType === 'UPDATE') {
    return {
      variables: {
        id: params.data.id,
        isVisible: params.data.isVisible,
        title: params.data.title,
      },
      parseResponse: (response) => {
        return { data: response.data.item };
      },
      query: gql`
          mutation updateUserVideo($id: UUID!, $isVisible: Boolean, $title: String) {
            item: updateUserVideo(id: $id, isVisible: $isVisible, title: $title) {
${UGVideoType}
            }
          }`,
    };
  }

  if (resource === 'UGVideo' && fetchType === 'DELETE') {
    return {
      variables: { id: params.id },
      parseResponse: (response) => {
        return { data: response.data.item };
      },
      query: gql`
          mutation deleteUserVideo($id: UUID!) {
            item: deleteUserVideo(id: $id) {
${UGVideoType}
            }
          }`,
    };
  }

  const builtQuery = buildQuery(introspection)(fetchType, resource, params);
  if (resource === 'UserVideo' && fetchType === 'GET_LIST') {
    builtQuery.variables.filter = { ...builtQuery.variables.filter, videoType: 'PROMO_VIDEO' };
    return {
      ...builtQuery,
      query: gql`
          query allUserVideos($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: UserVideoFilter) {
            items: allUserVideos(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) {
${UGVideoType}
            }
            total: _allUserVideosMeta(page: $page, perPage: $perPage, filter: $filter) {
              count
              __typename
            }
          }`,
    };
  }
  return builtQuery;
};