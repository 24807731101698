import {useCallback} from 'react';
import {useLogout, useNotify, useRefresh} from 'react-admin';
import config from '../config';
import {readAuthToken} from '../utils';

export const useGenerateNewCode = (characterId, newCodeLabel, setNewCodeLabel, setIsLoading) => {
  const logout = useLogout();
  const notify = useNotify();
  const refresh = useRefresh();

  return useCallback(async () => {
    const token = readAuthToken(logout);
    const variables = {
      input: {
        characterId,
        label: newCodeLabel,
      },
    };
    const body = {
      query: `
          mutation (
            $input: GenerateCharacterSecretCodeInput!
          ) {
            generateCharacterSecretCode (
                input: $input
            ) {
              id
              label
              value
              activatedAt
              disabledAt
            }
          }
        `,
      variables,
    };
    setIsLoading(true);
    const response = await fetch(`${config.API_HOST}/graphql`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: new Headers({
        authorization: `Bearer ${token}`,
        'content-type': 'application/json',
      }),
    });

    if (response.status !== 200 && response.status !== 400) {
      notify(response.statusText);
      return;
    }

    let responseBody;
    try {
      responseBody = await response.json();
    } catch (e) {
      notify(e.getMessage());
      if (e.getMessage() === 'Unauthorized request') {
        logout();
      }
    }

    if (responseBody.errors) {
      const error = responseBody.errors[0];
      notify(error.message, 'warning');
      return;
    }

    notify('Created new code', 'success');
    setNewCodeLabel('');
    refresh();
    setIsLoading(false);
  }, [characterId, newCodeLabel, logout, notify, refresh, setNewCodeLabel, setIsLoading]);
};

export const useToggleCode = (setIsLoading) => {
  const logout = useLogout();
  const notify = useNotify();
  const refresh = useRefresh();

  return useCallback(
    async (codeId, isDisabled) => {
      const token = readAuthToken(logout);
      const variables = {
        input: {
          id: codeId,
          isDisabled,
        },
      };
      const body = {
        query: `
          mutation (
            $input: ToggleCharacterSecretCodeInput!
          ) {
            toggleCharacterSecretCode (input: $input) {
              id, label, value, createdAt, activatedAt, disabledAt
            }
          }
          `,
        variables,
      };
      setIsLoading(true);
      const response = await fetch(`${config.API_HOST}/graphql`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: new Headers({
          authorization: `Bearer ${token}`,
          'content-type': 'application/json',
        }),
      });

      if (response.status !== 200 && response.status !== 400) {
        notify(response.statusText);
        return;
      }

      let responseBody;
      try {
        responseBody = await response.json();
      } catch (e) {
        notify(e.getMessage());
        if (e.getMessage() === 'Unauthorized request') {
          logout();
        }
      }

      if (responseBody.errors) {
        const error = responseBody.errors[0];
        notify(error.message, 'warning');
        return;
      }

      setIsLoading(false);
      notify('Toggled code', 'success');
      refresh();
    },
    [logout, notify, refresh, setIsLoading]
  );
};
