import {useLogout, useNotify} from 'react-admin';

import config from '../config';
import {readAuthToken} from '../utils';

const extractDataFromUrl = (urlWithQueryString) => {
  const url = urlWithQueryString.substring(0, urlWithQueryString.indexOf('?'));
  const sansProtocol = url.substring(8);
  const hostname = sansProtocol.substring(0, sansProtocol.indexOf('/'));
  const path = sansProtocol.substring(hostname.length + 1);
  console.log('path', path);
  const bucketName = path.substring(0, path.indexOf('/'));
  const key = path.substring(bucketName.length + 1);
  const region = hostname.split('.')[1];
  return {
    hostname,
    bucketName,
    key,
    region,
    url,
  };
};

export const useS3Upload = () => {
  const logout = useLogout();
  const notify = useNotify();
  const token = readAuthToken(logout);

  return async (file, folder) => {
    const createFileMutationInput = {
      url: undefined,
      originalFilename: file.name,
      mimeType: file.type,
      meta: {
        region: undefined,
        originalUploadUrl: undefined,
        size: file.size,
        bucket: undefined,
      },
    };

    const presignedUrlResponse = await fetch(`${config.API_HOST}/admin/s3-generate-url`, {
      method: 'POST',
      body: JSON.stringify(
        {
          fileFolder: folder,
          fileName: file.name,
          fileType: file.type,
          fileSize: file.size,
        },
        null,
        2
      ),
      headers: new Headers({
        authorization: `Bearer ${token}`,
        'content-type': 'application/json',
      }),
    });

    if (presignedUrlResponse.status !== 200) {
      if (presignedUrlResponse.status === 401) {
        logout();
        return null;
      }
      notify(presignedUrlResponse.statusText);
      return null;
    }

    const presignedUrlResponseBody = await presignedUrlResponse.json();

    const {bucket, url} = presignedUrlResponseBody;

    const {hostname, region, key, url: originalUploadUrl} = extractDataFromUrl(url);
    createFileMutationInput.meta.bucket = bucket;
    createFileMutationInput.meta.hostname = hostname;
    createFileMutationInput.meta.region = region;
    createFileMutationInput.meta.originalUploadUrl = originalUploadUrl;
    createFileMutationInput.url = key;

    const response = await fetch(url, {
      method: 'PUT',
      body: file,
      headers: new Headers({
        'Content-Type': file.type,
      }),
    });

    if (response.status !== 200) {
      if (response.status === 401) {
        logout();
        return null;
      }
      notify(response.statusText);
      return null;
    }

    const body = {
      query: `
        mutation ($input: FileInput!) {
          createFile(input: $input) {
            id, meta { size, bucket }, mimeType, originalFilename, url
          }
        }
      `,
      variables: {
        input: createFileMutationInput,
      },
    };
    const createFileResponse = await fetch(`${config.API_HOST}/graphql`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: new Headers({
        authorization: `Bearer ${token}`,
        'content-type': 'application/json',
      }),
    });

    try {
      const createFileResponseJson = await createFileResponse.json();
      if (createFileResponseJson.errors) {
        alert(createFileResponseJson.errors.map((error) => error.message).join('\n'));
        return null;
      }
      return createFileResponseJson.data.createFile;
    } catch (e) {
      alert(e);
    }
  };
};
