/* global process */

export default {
  API_HOST: process.env.REACT_APP_API_HOST,
  PROMOTE_TARGETS:
    (process.env.REACT_APP_PROMOTE_TARGETS && process.env.REACT_APP_PROMOTE_TARGETS.split(',')) ||
    [],
  PROMOTE_TARGET_NAMES:
    (process.env.REACT_APP_PROMOTE_TARGET_NAMES &&
      process.env.REACT_APP_PROMOTE_TARGET_NAMES.split(',')) ||
    [],
};
