import React from 'react';
import {Datagrid, FileField, List, Show, SimpleShowLayout, TextField} from 'react-admin';
import {ResourcePagination} from './utils';


export const FileList = (props) => (
  <List
    {...props}
    perPage={25}
    pagination={<ResourcePagination />}
  >
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="url" />
      <TextField source="originalFilename" />
      <TextField source="mimeType" />
    </Datagrid>
  </List>
);

export const FileShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <FileField source="url" />
      <TextField source="originalFilename" />
      <TextField source="mimeType" />
    </SimpleShowLayout>
  </Show>
);
