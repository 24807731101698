import * as React from 'react';
import PropTypes from 'prop-types';
import {useRecordContext} from 'react-admin';

const VideoField = (props) => {
  const {source} = props;
  const record = useRecordContext(props);
  return (
    <div style={{marginTop: 32, marginBottom: 24}}>
      <video width="600" height="400" controls>
        <source src={record[source]} />
      </video>
    </div>
  );
};

VideoField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default VideoField;
