import React, {useCallback, useState} from 'react';

import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import {
  useLogout,
  useNotify,
} from 'react-admin';

import config from './../config';
import {readAuthToken} from './../utils';

const RegenerateAttributionButton = ({ userVideoId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const logout = useLogout();
  const notify = useNotify();
  const handleRegenerateAttributionButtonClick = useCallback(async () => {
    setIsLoading(true);
    const token = readAuthToken(logout);
    const variables = {
      id: userVideoId,
    };
    const body = {
      query: `
        mutation($id: UUID!) {
          regenerateUserVideoAttributionLinks(id: $id) {
            id
            url
            isReadyToStream
            meta {
              size
              pctComplete
            }
          }
        }
      `,
      variables,
    };
    try {
      await fetch(`${config.API_HOST}/graphql`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: new Headers({
          authorization: `Bearer ${token}`,
          'content-type': 'application/json',
        }),
      });
      setIsLoading(false);
      window.location.reload();
    } catch (error) {
      setIsLoading(false);
      if (error.graphQLErrors && error.graphQLErrors.length) {
        notify(error.graphQLErrors[0].message, 'error');
      } else if (error) {
        notify(error.message, 'error');
      }
    }
  }, [logout, notify, userVideoId]);
  return (
    <Button variant="contained" color="primary" onClick={handleRegenerateAttributionButtonClick}>
      {isLoading ? 'Processing...' : 'Regenerate attribution'}
    </Button>
  );
};

RegenerateAttributionButton.propTypes = {
  userVideoId: PropTypes.string.isRequired,
};

export default RegenerateAttributionButton;
